import React from 'react'
import PrimaryLayout from '../components/layouts/PrimaryLayout'

const NotFoundPage = () => (
  <PrimaryLayout>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </PrimaryLayout>
)

export default NotFoundPage
